import React, { useEffect } from "react";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import orange from "../Assets/Images/orange.png";
import visas12 from "../Assets/Images/visas12.PNG";
import "./project.css";
import king from "../Assets/Project/king.svg";
import iphone from "../Assets/Project/iphone.svg";
import blue from "../Assets/Images/blue.png";
import avatarman from "../Assets/Images/avatarman.png";
import visas1 from "../Assets/Images/visas1.PNG";
import visas2 from "../Assets/Images/visas2.PNG";
import { FiSearch } from "react-icons/fi";
import rocket from "../Assets/Careers/rocket.svg";
import colorthree from "../Assets/Images/colorthree.png";
import wordpress from "../Assets/Images/wordpress.png";
import bookly from "../Assets/Images/bookly1.png";

import GoogleMaps from "../Assets/Images/GoogleMaps.png";
import bootstrap from "../Assets/Images/wpforms2.png";
import Techcard from "../components/Techcard";
function Project1() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Row
        className="justify-content-center"
        style={{ marginBottom: "180px", marginTop: "30px" }}
      >
        <Col lg={5} xs={10} md={5} style={{ marginTop: "60px" }}>
          <h1
            style={{
              position: "relative",
              top: "-50px",
              fontSize: "70px",
              fontWeight: "700",
            }}
          >
            Visas2go
          </h1>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "-50px",
              fontWeight: "500",
              color: "grey",
            }}
          >
            {" "}
            We deal with all types of visa applications, in all degrees of
            complexity, and are able to assist you at any stage of your visa
            application, should you seek help pre or post lodgement or if you
            wish to make an appeal for your refused application. We understand
            how complicated visas can be and recognise the vastness of
            information that exists to comb through should you wish to undertake
            visa applications.
          </p>
          <div style={{ marginTop: "-50px" }}>
            <Button variant="outline-primary">Consultancy</Button>{" "}
            <Button variant="outline-warning">Advisory</Button>{" "}
            <Button variant="outline-danger">Development</Button>{" "}
          </div>
        </Col>
        <Col lg={5} xs={12} md={5}>
          <img src={visas12} className="project-one" />
        </Col>
      </Row>

      <Row
        className="justify-content-center"
        style={{ marginBottom: "200px", marginTop: "100px" }}
      >
        <Col lg={10} md={10} xs={10}>
          <h2 className="about-head">Our Core Values</h2>
          <Row
            className="justify-content-between"
            style={{ marginTop: "50px" }}
          >
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={iphone}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
                <p style={{ fontWeight: 700, fontSize: "20px" }}>1 Screens</p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",

                    fontWeight: "500",
                    color: "grey",
                  }}
                >
                  {" "}
                  A Single Page Application with 5 different sections. About
                  Us,Why Choose Us,Services Offered,Contact Us,Header/Footer
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={king}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>
                Professional Design
              </p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                Professional Design with fade in animation for images and
                content. The Google Map clearly displays the location of the
                company.
              </p>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div
                className="justify-content-center"
                style={{ fontSize: "32px" }}
              >
                {" "}
                <FiSearch />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>Pixel Perfect</p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                All the content and images are very well responsive on different
                mobiles,laptop screens and tabs.
              </p>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={rocket}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>Modern Tools</p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                Have made use of latest Bootstrap 5 that is very robust.
              </p>
            </Col>
          </Row>{" "}
        </Col>
      </Row>
      <h2 className="about-head">Typography</h2>

      <Row className="justify-content-center">
        <Col lg={5} xs={10} md={5} style={{ marginTop: "100px" }}>
          <h1
            style={{
              position: "relative",
              top: "-50px",
              fontSize: "50px",
              fontWeight: "700",
            }}
          >
            Titlium Web
          </h1>
          <Row>
            <Col lg={2} xs={6}>
              <h1
                className="project-font"
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "",
                  fontWeight: "300",
                }}
              >
                Light
              </h1>
            </Col>
            <Col lg={3} xs={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  fontWeight: "400",
                }}
              >
                Regular
              </h1>
            </Col>{" "}
            <Col lg={4} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  fontWeight: "550",
                }}
              >
                Semi-Bold
              </h1>
            </Col>
            <Col lg={3} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  fontWeight: "800",
                }}
              >
                Bold
              </h1>
            </Col>
          </Row>
        </Col>
        <Col lg={4} xs={10} md={5}>
          <h1
            style={{
              fontFamily: "revert",
              color: "#E7E7E7",
              fontSize: "200px",
            }}
          >
            Aa
          </h1>{" "}
        </Col>
        <Col lg={5} xs={10} md={5} style={{ marginTop: "100px" }}>
          <h1
            style={{
              position: "relative",
              top: "-50px",
              fontSize: "50px",
              fontWeight: "700",
            }}
          >
            Sans-serif
          </h1>

          <Row>
            <Col lg={2} xs={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "300",
                }}
              >
                Light
              </h1>
            </Col>
            <Col lg={3} xs={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "400",
                }}
              >
                Regular
              </h1>
            </Col>{" "}
            <Col lg={4} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "550",
                }}
              >
                Semi-Bold
              </h1>
            </Col>
            <Col lg={3} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  fontWeight: "800",
                }}
              >
                Bold
              </h1>
            </Col>
          </Row>
        </Col>
        <Col lg={4} xs={11} md={5}>
          <h1
            style={{
              fontFamily: "revert",
              color: "#E7E7E7",
              fontSize: "200px",
            }}
          >
            Aa
          </h1>{" "}
        </Col>
      </Row>
      <Container>
        <h2 className="headingtwo">Colors</h2>
      </Container>
      <Row style={{ justifyContent: "center", marginBottom: "200px" }}>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img variant="top" src={orange} style={{ height: "180px" }} />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>#E6373D</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img variant="top" src={blue} style={{ height: "180px" }} />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>#002D5B</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img
              variant="top"
              src={colorthree}
              style={{ height: "180px" }}
            />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>#6161E8</h5>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Container>
        <Techcard
          techtitle="Tools & Technologies"
          subtitle="Google Maps"
          subtitle1="Wordpress"
          subtitle2="Bookly"
          subtitle3="WP forms"
          techimg={GoogleMaps}
          techimg1={wordpress}
          techimg2={bookly}
          techimg3={bootstrap}
        />

        <h2 className="about-head" style={{ marginTop: "100px" }}>
          Clients
        </h2>
        <Row style={{ marginBottom: "75px", justifyContent: "center" }}>
          <Col lg={10}>
            <Card className="shadow" style={{ marginBottom: "70px" }}>
              <Row className="justify-space-around">
                <Col lg={3} md={4} xs={12} className="project-test">
                  <img src={avatarman} className="project-img" />
                  <br />
                  <h3 className="headingthree">-Managing Director</h3>
                  <p className="project-text">SKP group</p>
                </Col>
                <Col lg={8} xs={12} md={6} className="project-eleven">
                  "Texerd is a great company to work with as they are always
                  responsive and willing to do what it takes to get the job done
                  right and in a timely manner. They are very reliable and
                  intelligent. It is clear right away that Texerd was the right
                  choice to revamp our website, logo, and other items. Thank you
                  Texerd"
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Container>
          <h2
            className="about-head"
            style={{ marginTop: "100px", textAlign: "center" }}
          >
            Screenshots
          </h2>
        </Container>
        <Row style={{ marginBottom: "100px" }}>
          <Col lg={8} xs={12} md={6}>
            <img height="400px" src={visas2} />
            <br />
          </Col>
        </Row>
        <Row style={{ marginBottom: "100px" }}>
          <Col lg={8} xs={12} md={6}>
            <img height="400px" src={visas1} />
            <br />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Project1;
