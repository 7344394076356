import React, { useEffect } from "react";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import avatarman from "../Assets/Images/avatarman.png";

import king from "../Assets/Project/king.svg";
import iphone from "../Assets/Project/iphone.svg";
import image1 from "../Assets/Images/image1.png";
import "./project.css";
import colorten from "../Assets/Images/colorten.png";
import colortwo from "../Assets/Images/colortwo.png";
import dsv6 from "../Assets/Images/dsv1.jpeg";
import app3 from "../Assets/Images/app3.jpeg";
import dsv1 from "../Assets/Project/dsv1.PNG";
import app4 from "../Assets/Images/app4.jpeg";
import rocket from "../Assets/Careers/rocket.svg";
import colorthree from "../Assets/Images/colorthree.png";
import colorfour from "../Assets/Images/colorfour.png";
import Valerie from "../Assets/Images/Valerie.jpg";
import dsv from "../Assets/dsv.mp4";
import firebasesvg1 from "../Assets/Images/firebasesvg1.svg";
import dart from "../Assets/Images/dart.svg";
import fluttersvg from "../Assets/Images/fluttersvg.svg";
import nodejs from "../Assets/Services/WebDev/nodejs.svg";
import react from "../Assets/Services/WebDev/react.svg";
import tailwind from "../Assets/Services/WebDev/tailwind.svg";
import Techcard from "../components/Techcard";

import ReactPlayer from "react-player";
import { FiSearch } from "react-icons/fi";
import { BiDotsVertical } from "react-icons/bi";

function Project2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Row
        className="justify-content-center"
        style={{ marginBottom: "180px", marginTop: "30px" }}
      >
        <Col lg={5} xs={10} md={5} style={{ marginTop: "60px" }}>
          <h1
            style={{
              position: "relative",
              top: "-50px",
              fontSize: "70px",
              fontWeight: "700",
            }}
          >
            Direct Sarees From Veavers{" "}
          </h1>
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "-50px",
              fontWeight: "500",
              color: "grey",
            }}
          >
            {" "}
            DSV is an ecommerce mobile app for sarees. This includes all types
            of sarees including fabric material, embroidery work, party wear,
            casual and many more… Connect with an amazing network of dealers
            that supply high-quality, trending products that the customers love.
            We also conduct regular quality checks to ensure our customers get
            products of only the best quality. Our products are updated on a
            regular basis with high quality photos.
          </p>
          <div style={{ marginTop: "-50px" }}>
            <Button variant="outline-primary">E-commerce</Button>{" "}
            <Button variant="outline-warning">Design</Button>{" "}
            <Button variant="outline-danger">Development</Button>{" "}
          </div>
        </Col>
        <Col lg={5} xs={12} md={5}>
          <img src={app3} style={{ width: "100%", height: "400px" }} />
        </Col>
      </Row>

      <Row
        className="justify-content-center"
        style={{ marginBottom: "200px", marginTop: "100px" }}
      >
        <Col lg={10} md={10} xs={10}>
          <h2 className="about-head">Our Core Values</h2>
          <Row
            className="justify-content-between"
            style={{ marginTop: "50px" }}
          >
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={iphone}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
                <p style={{ fontWeight: 700, fontSize: "20px" }}> Screens</p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",

                    fontWeight: "500",
                    color: "grey",
                  }}
                >
                  {" "}
                  Different Screens for Customer, Admin and Dealer.
                </p>
              </div>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={king}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>
                Instagram Style Design
              </p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                The instagram style design is very engaging and just let’s you
                seamlessly swipe above,like and favourite products.
              </p>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div
                className="justify-content-center"
                style={{ fontSize: "32px" }}
              >
                {" "}
                <FiSearch />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>Pixel Perfect</p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                The quality of the images uploaded is not lost,so that the
                beauty of the sarees is not lost lost.
              </p>
            </Col>
            <Col lg={3} md={6} xs={6}>
              <div className="justify-content-center">
                {" "}
                <img
                  src={rocket}
                  style={{
                    width: "30px",
                    height: "50px",
                    marginLeft: "8px",
                  }}
                />
              </div>
              <p style={{ fontWeight: 700, fontSize: "20px" }}>Modern Tools</p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",

                  fontWeight: "500",
                  color: "grey",
                }}
              >
                {" "}
                Built using hybrid technology Flutter for both Ios and Android
                phones.
              </p>
            </Col>
          </Row>{" "}
        </Col>
      </Row>
      <h2 className="about-head">Typography</h2>

      <Row className="justify-content-center">
        <Col lg={5} xs={10} md={5} style={{ marginTop: "100px" }}>
          <h1
            style={{
              position: "relative",
              top: "-50px",
              fontSize: "50px",
              fontWeight: "700",
            }}
          >
            Titlium Web
          </h1>
          <Row>
            <Col lg={2} xs={6}>
              <h1
                className="project-font"
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "",

                  fontWeight: "300",
                }}
              >
                Light
              </h1>
            </Col>
            <Col lg={3} xs={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "400",
                }}
              >
                Regular
              </h1>
            </Col>{" "}
            <Col lg={4} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "550",
                }}
              >
                Semi-Bold
              </h1>
            </Col>
            <Col lg={3} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  fontWeight: "800",
                }}
              >
                Bold
              </h1>
            </Col>
          </Row>
          <p
            style={{
              display: "flex",
              position: "relative",
              top: "-50px",
              fontWeight: "500",
              color: "grey",
            }}
          >
            {" "}
            At Texerd,we are team of young, dynamic and vibrant peopleAt
          </p>
        </Col>
        <Col lg={4} xs={10} md={5}>
          <h1
            style={{
              fontFamily: "revert",
              color: "#E7E7E7",
              fontSize: "200px",
            }}
          >
            Aa
          </h1>{" "}
        </Col>
        <Col lg={5} xs={10} md={5} style={{ marginTop: "100px" }}>
          <h1
            style={{
              position: "relative",
              top: "-50px",
              fontSize: "50px",
              fontWeight: "700",
            }}
          >
            Sans-serif
          </h1>

          <Row>
            <Col lg={2} xs={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "300",
                }}
              >
                Light
              </h1>
            </Col>
            <Col lg={3} xs={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "400",
                }}
              >
                Regular
              </h1>
            </Col>{" "}
            <Col lg={4} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",

                  fontWeight: "550",
                }}
              >
                Semi-Bold
              </h1>
            </Col>
            <Col lg={3} xs={6} md={6}>
              <h1
                style={{
                  position: "relative",
                  top: "-50px",
                  fontSize: "30px",
                  fontFamily: "fantasy",
                  fontWeight: "800",
                }}
              >
                Bold
              </h1>
            </Col>
          </Row>
          <p
            style={{
              display: "flex",
              position: "relative",
              top: "-50px",
              fontWeight: "500",
              color: "grey",
            }}
          >
            {" "}
            At Texerd,we are team of young, dynamic and vibrant peopleAt
          </p>
        </Col>
        <Col lg={4} xs={11} md={5}>
          <h1
            style={{
              fontFamily: "revert",
              color: "#E7E7E7",
              fontSize: "200px",
            }}
          >
            Aa
          </h1>{" "}
        </Col>
      </Row>
      <Container>
        <h2 className="headingtwo">Colors</h2>
      </Container>
      <Row style={{ justifyContent: "center", marginBottom: "200px" }}>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img
              variant="top"
              src={colorten}
              style={{ height: "180px" }}
            />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>6161e8</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img
              variant="top"
              src={colortwo}
              style={{ height: "180px" }}
            />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>6161e8</h5>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={2} md={3} xs={10}>
          <Card className="shadow" style={{ marginBottom: "20px" }}>
            <Card.Img
              variant="top"
              src={colorthree}
              style={{ height: "180px" }}
            />
            <Card.Body style={{ display: "flex", justifyContent: "center" }}>
              <h5>6161e8</h5>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Container>
        <Techcard
          techtitle="Tools & Technologies"
          subtitle="Flutter"
          subtitle1="Firebase"
          subtitle2="Dart"
          techimg={fluttersvg}
          techimg1={firebasesvg1}
          techimg2={dart}
          techimg3={nodejs}
          style={{ width: "500px" }}
        />
        <h2 className="headingtwo">Clients</h2>
        <Row style={{ marginBottom: "75px", justifyContent: "center" }}>
          <Col lg={10}>
            <Card className="shadow" style={{ marginBottom: "70px" }}>
              <Row className="justify-space-around">
                <Col lg={3} md={4} xs={12} className="project-test">
                  <img src={avatarman} className="project-img" />
                  <br />
                  <h3 className="headingthree">-Managing Director</h3>
                  <p className="project-text">DSV</p>
                </Col>
                <Col lg={8} xs={12} md={6} className="project-eleven">
                  I approached Texerd for an e-commerce mobile application for
                  our family business . They clearly understood the gap between
                  the dealers and customers and built an extraordinary app to
                  bridge that gap . This has helped me boost my sales and made
                  my work easy. Thank you Texerd for being there and addressing
                  all my concerns.
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <h2 className="headingtwo">Screenshots</h2>
        </Row>
        <Row
          style={{
            marginBottom: "100px",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Col lg={4} xs={12} md={6}>
            <img height="400px" src={app3} />
          </Col>

          <Col lg={4} xs={12} md={6}>
            <img height="400px" src={dsv6} />
          </Col>

          <Col
            style={{ display: "flex", justifyContent: "center" }}
            lg={4}
            xs={12}
            md={6}
          >
            <img height="400px" src={app4} />
          </Col>
          <Col
            style={{ display: "flex", justifyContent: "center" }}
            lg={4}
            xs={12}
            md={6}
          ></Col>
        </Row>
        <Row>
          <Col lg={10}>
            <video width="700" height="450" controls poster={dsv1}>
              <source
                src={dsv}
                type="video/mp4"
                style={{ justifyItems: "center" }}
              />
              <br />
            </video>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Project2;
