import React from "react";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Form,
  FormControl,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import "../pages/services.css";
import "../pages/tech.css";
import StripsCircled from "../Assets/Main/Hero/StripsCircled.svg";
import angular from "../Assets/Services/WebDev/angular.svg";
import nodejs from "../Assets/Services/WebDev/nodejs.svg";
import react from "../Assets/Services/WebDev/react.svg";
import tailwind from "../Assets/Services/WebDev/tailwind.svg";
function Techcard(props) {
  return (
    <div>
      <Row className="justify-content-center" style={{ marginBottom: "50px" }}>
        <Col md={6} lg={12} xs={12}>
          <h1
            style={{
              textAlign: "center",
              fontWeight: "700",

              marginTop: "50px",
            }}
          >
            {props.techtitle}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xs={12}>
          <Row className="tech-cards">
            <Col md={4} lg={4} xs={10}>
              <Card
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginLeft: "15px",
                  textAlign: "center",
                  border: "none",
                }}
                className="shadow"
              >
                <div className="justify-content-center">
                  {" "}
                  <img
                    src={props.techimg}
                    style={{
                      width: "75px",
                      marginTop: "30px",
                      marginBottom: "30px",
                    }}
                  />{" "}
                </div>
                <h3 className="tech-cards">{props.subtitle}</h3>
                <h6 style={{ color: "grey" }}>{props.text1}</h6>
              </Card>
            </Col>
            <Col md={4} lg={4} xs={10}>
              <Card
                style={{
                  marginTop: "10px",
                  marginLeft: "15px",
                  textAlign: "center",
                  border: "none",
                  marginBottom: "10px",
                }}
                className="shadow"
              >
                <div className="justify-content-center">
                  {" "}
                  <img
                    src={props.techimg1}
                    style={{
                      width: "70px",
                      marginTop: "30px",
                      marginBottom: "30px",
                    }}
                  />{" "}
                </div>
                <h3 className="tech-cards">{props.subtitle1}</h3>
                <h6 style={{ color: "grey" }}>{props.text2}</h6>
              </Card>
            </Col>
            <Col md={4} lg={4} xs={10}>
              <Card
                style={{
                  marginTop: "10px",
                  marginLeft: "15px",
                  textAlign: "center",
                  border: "none",
                  marginBottom: "10px",
                }}
                className="shadow"
              >
                <div className="justify-content-center">
                  {" "}
                  <img
                    src={props.techimg2}
                    style={{
                      width: "70px",
                      marginTop: "30px",
                      marginBottom: "30px",
                    }}
                  />{" "}
                </div>
                <h3 className="tech-cards">{props.subtitle2}</h3>
                <h6 style={{ color: "grey" }}>{props.text2}</h6>
              </Card>
            </Col>
            <Col md={4} lg={4} xs={10}>
              <Card
                style={{
                  marginTop: "10px",
                  marginLeft: "15px",
                  textAlign: "center",
                  border: "none",
                  marginBottom: "10px",
                }}
                className="shadow"
              >
                <div className="justify-content-center">
                  {" "}
                  <img
                    src={props.techimg3}
                    style={{
                      width: "65px",
                      marginTop: "30px",
                      marginBottom: "30px",
                    }}
                  />{" "}
                </div>
                <h3 className="tech-cards">{props.subtitle3}</h3>
                <h6 style={{ color: "grey" }}>{props.text3}</h6>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Techcard;
